<template>
  <div class="column" ref="tabLay">
    <transition
      name="fade"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div class="tab_top">
        <div
          :class="current == 0 ? 'select m68' : 'normal m68'"
          @click="choiceClick(0)"
        >
          LGA课程
        </div>
        <div
          :class="current == 1 ? 'select m46' : 'normal m46'"
          @click="choiceClick(1)"
        >
          PES运动报告
        </div>
        <div
          :class="current == 2 ? 'select m50' : 'normal m50'"
          @click="choiceClick(2)"
        >
          KFA小小运动会
        </div>
        <div
          :class="current == 3 ? 'select m74' : 'normal m74'"
          @click="choiceClick(3)"
        >
          体育星计划
        </div>
        <div
          :class="current == 4 ? 'select m87' : 'normal m87'"
          @click="choiceClick(4)"
        >
          精英特训
        </div>
        <div
          :class="current == 5 ? 'select m66' : 'normal m66'"
          @click="choiceClick(5)"
        >
          TA计划
        </div>
        <div
          :class="current == 6 ? 'select m54' : 'normal m54'"
          @click="choiceClick(6)"
        >
          HHDD红花朵朵
        </div>
        <div
          :class="current == 7 ? 'select m210' : 'normal m210'"
          @click="choiceClick(7)"
        >
          PLUS会员
        </div>
        <div class="normal" @click="goHome">首页</div>
      </div>
    </transition>
    <div class="h55"></div>
    <div class="row_center">
      <img src="../../assets/img/home/home/LGA/Pic_LGA_Child.png" />
    </div>

    <div id="anim1" class="row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <img
          v-show="anim1"
          src="../../assets/img/home/home/LGA/Pic_Title_LGA.png"
        />
      </transition>
    </div>
    <div id="anim2" class="m35 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div v-show="anim2" class="mk_text">
          <span
            >遵循英国国家级幼儿园学前教育体系,以英足总青少年发展管理体系为基础</span
          >
          <span>引入英国原创视频课程</span>
        </div>
      </transition>
    </div>
    <div id="course" class="ddz_l">
      <div class="ppp">
        <div
          :class="courseType == 0 || mounthType == 0 ? 'select1' : 'normal1'"
          @click="courseClick(0)"
          @mouseover="Mouseover(0)"
          @mouseleave="Mouseleave()"
        >
          体<br />验<br />课
        </div>
        <div
          :class="
            courseType == 1 || mounthType == 1 ? 'select1 mt4' : 'normal1 mt4'
          "
          @click="courseClick(1)"
          @mouseover="Mouseover(1)"
          @mouseleave="Mouseleave()"
        >
          小<br />班<br />课
        </div>
        <div
          :class="
            courseType == 2 || mounthType == 2 ? 'select1 mt4' : 'normal1 mt4'
          "
          @click="courseClick(2)"
          @mouseover="Mouseover(2)"
          @mouseleave="Mouseleave()"
        >
          中<br />班<br />课
        </div>
        <div
          :class="
            courseType == 3 || mounthType == 3 ? 'select1 mt4' : 'normal1 mt4'
          "
          @click="courseClick(3)"
          @mouseover="Mouseover(3)"
          @mouseleave="Mouseleave()"
        >
          大<br />班<br />课
        </div>
      </div>
      <div class="column3">
        <div class="sticky width823">
          <div class="pos_rel">
            <div class="row_center tab_pos">
              <div class="course_select">课程视频</div>
              <div class="yd_normal" @click="clickYd">月度之星</div>
            </div>
            <img
              class="logo_pos"
              src="../../assets/img/home/home/LGA/Pic_Logo_LGA.png"
            />
          </div>
        </div>
        <div class="width822">
          <div id="tyId" v-for="item in tyList" :key="item.id">
            <div class="item_bg">
              <div class="image_l">
                <!-- <video
                  class="image"
                  :id="'tvideo' + index"
                  controls
                  preload="true"
                  autoplay="false"
                  :poster="item.videoPaly.videoBase.coverURL"
                  @timeupdate="updateTime"
                  @pause="toPause"
                  @play="toPlay"
                >
                  <source
                    src=""
                    type="application/x-mpegURL"
                  />
                </video> -->
                <img
                  v-if="!item.isLoad"
                  class="image"
                  :src="
                    item.videoPaly.videoBase
                      ? item.videoPaly.videoBase.coverURL
                      : ''
                  "
                />
                <img
                  v-show="!item.isPlay"
                  class="play"
                  src="../../assets/img/home/home/LGA/Icon_Class_Play.png"
                  @click="play(item)"
                />
                <div
                  v-show="item.isLoad"
                  class="image"
                  :id="'tvideo' + item.id"
                ></div>
              </div>
              <div class="row">
                <div class="label_text">免费课程</div>
                <div class="text1 flex">{{ item.videoname }}</div>
                <!-- <div class="column_l">
                  <img
                    v-if="item.isPraise"
                    class="image"
                    src="../../assets/img/home/home/LGA/Icon_Class_Like.png"
                    @click="cancelPraise(item)"
                  />
                  <img
                    v-else
                    class="image"
                    src="../../assets/img/home/home/LGA/Icon_Class_Like_Unc.png"
                    @click="Praise(item)"
                  />
                  <div class="num">点赞{{ item.assisNum }}</div>
                </div> -->
              </div>
              <div class="line"></div>
              <div class="row1">
                <div class="text flex">
                  {{ item.videoinfo }}
                </div>
                <div class="column_l">
                  <div class="time">观看次数</div>
                  <div class="time">{{ item.lookNumStr }}</div>
                </div>
              </div>
            </div>
          </div>
          <div id="smallId" v-for="item in smallList" :key="item.id">
            <div class="item_bg">
              <div class="image_l">
                <img
                  v-if="!item.isLoad"
                  class="image"
                  :src="
                    item.videoPaly.videoBase
                      ? item.videoPaly.videoBase.coverURL
                      : ''
                  "
                />
                <img
                  v-show="!item.isPlay"
                  class="play"
                  src="../../assets/img/home/home/LGA/Icon_Class_Play.png"
                  @click="play1(item)"
                />
                <div
                  v-show="item.isLoad"
                  class="image"
                  :id="'svideo' + item.id"
                ></div>
              </div>
              <div class="row">
                <div class="label_text1">小班课程</div>
                <div class="text1 flex">{{ item.videoname }}</div>
                <div class="sk" @click="goSk(item)">一键上课</div>
                <!-- <div class="column_l">
                  <img
                    v-if="item.isPraise"
                    class="image"
                    src="../../assets/img/home/home/LGA/Icon_Class_Like.png"
                  />
                  <img
                    v-else
                    class="image"
                    src="../../assets/img/home/home/LGA/Icon_Class_Like_Unc.png"
                  />
                  <div class="num">点赞{{ item.assisNum }}</div>
                </div> -->
              </div>
              <div class="line"></div>
              <div class="row1">
                <div class="text flex">
                  {{ item.videoinfo }}
                </div>
                <div class="column_l">
                  <div class="time">观看次数</div>
                  <div class="time">{{ item.lookNumStr }}</div>
                </div>
              </div>
            </div>
          </div>
          <div id="middleId" v-for="item in middleList" :key="item.id">
            <div class="item_bg">
              <div class="image_l">
                <img
                  v-if="!item.isLoad"
                  class="image"
                  :src="
                    item.videoPaly.videoBase
                      ? item.videoPaly.videoBase.coverURL
                      : ''
                  "
                />
                <img
                  v-show="!item.isPlay"
                  class="play"
                  src="../../assets/img/home/home/LGA/Icon_Class_Play.png"
                  @click="play2(item)"
                />
                <div
                  v-show="item.isLoad"
                  class="image"
                  :id="'mvideo' + item.id"
                ></div>
              </div>
              <div class="row">
                <div class="label_text2">中班课程</div>
                <div class="text1 flex">{{ item.videoname }}</div>
                <div class="sk" @click="goSk(item)">一键上课</div>
                <!-- <div class="column_l">
                  <img
                    v-if="item.isPraise"
                    class="image"
                    src="../../assets/img/home/home/LGA/Icon_Class_Like.png"
                  />
                  <img
                    v-else
                    class="image"
                    src="../../assets/img/home/home/LGA/Icon_Class_Like_Unc.png"
                  />
                  <div class="num">点赞{{ item.assisNum }}</div>
                </div> -->
              </div>
              <div class="line"></div>
              <div class="row1">
                <div class="text flex">
                  {{ item.videoinfo }}
                </div>
                <div class="column_l">
                  <div class="time">观看次数</div>
                  <div class="time">{{ item.lookNumStr }}</div>
                </div>
              </div>
            </div>
          </div>
          <div id="largeId" v-for="item in largeList" :key="item.id">
            <div class="item_bg">
              <div class="image_l">
                <img
                  v-if="!item.isLoad"
                  class="image"
                  :src="
                    item.videoPaly.videoBase
                      ? item.videoPaly.videoBase.coverURL
                      : ''
                  "
                />
                <img
                  v-show="!item.isPlay"
                  class="play"
                  src="../../assets/img/home/home/LGA/Icon_Class_Play.png"
                  @click="play3(item)"
                />
                <div
                  v-show="item.isLoad"
                  class="image"
                  :id="'lvideo' + item.id"
                ></div>
              </div>
              <div class="row">
                <div class="label_text3">大班课程</div>
                <div class="text1 flex">{{ item.videoname }}</div>
                <div class="sk" @click="goSk(item)">一键上课</div>
                <!-- <div class="column_l">
                  <img
                    v-if="item.isPraise"
                    class="image"
                    src="../../assets/img/home/home/LGA/Icon_Class_Like.png"
                    @click="cancelPraise(item)"
                  />
                  <img
                    v-else
                    class="image"
                    src="../../assets/img/home/home/LGA/Icon_Class_Like_Unc.png"
                    @click="Praise(item)"
                  />
                  <div class="num">点赞{{ item.assisNum }}</div>
                </div> -->
              </div>
              <div class="line"></div>
              <div class="row1">
                <div class="text flex">
                  {{ item.videoinfo }}
                </div>
                <div class="column_l">
                  <div class="time">观看次数</div>
                  <div class="time">{{ item.lookNumStr }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="h600" class="h600"></div>
    <div class="alert" v-show="alertShow" ref="alert">
      <div class="alert_bg">
        <img
          class="closeImg"
          src="../../assets/img/home/home/Icon_Closed.png"
          @click="confirm"
        />
        <div class="bb">
          <img
            class="imgg"
            src="../../assets/img/common/icon_hint_normal.png"
          />
        </div>
        <div class="message">观看全部视频内容请‘一键上课’</div>
      </div>
    </div>
    <popUp @bindSet="setBillDetail" @bindSkip="setBillSkip" v-if="isRoute" />
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import popUp from "../zhSchool/components/popup/popQue.vue";
export default {
  name: "LGAHome",
  components: {
    popUp,
  },
  data() {
    return {
      current: 0,
      anim1: false,
      anim2: false,
      anim3: false,
      courseType: 0,
      mounthType: -1,
      tyVideo: {},
      tyList: [],
      smallList: [],
      smallVideo: {},
      middleList: [],
      middleVideo: {},
      largeList: [],
      largeVideo: {},
      currentVideoId: null,
      currentItem: null,
      alertShow: false,
      isRoute: false,
    };
  },

  methods: {
    play(item) {
      item.isLoad = true;
      item.isPlay = true;
      if (this.tyVideo[item.id] != null) {
        this.tyVideo[item.id].play();
      } else {
        var that = this;
        this.$nextTick(() => {
          var source = {};
          if (item.videoPaly.playInfoList) {
            item.videoPaly.playInfoList.forEach((item1) => {
              source[item1.definition] = item1.playURL;
            });
          }
          let player = new window.Aliplayer(
            {
              format: "m3u8",
              encryptType: "1",
              id: "tvideo" + item.id,
              source: JSON.stringify(source),
              defaultDefinition: "SD",
              autoplay: true,
              width: "100%",
              height: "100%",
              cover: item.videoPaly.videoBase.coverURL,
              isLive: false,
              rePlay: false,
              preload: false,
            },
            function (player) {
              console.log(player);
            }
          );
          player.on("play", function () {
            if (that.currentVideoId != null) {
              if (that.currentItem.id == item.id) {
                if (item.showAlert) {
                  item.showAlert = false;
                  item.isPlay = true;
                  that.currentVideoId.seek(0);
                  that.currentVideoId.replay();
                  that.playNum();
                }
              } else {
                that.currentVideoId.pause();
                that.currentItem.isPlay = false;
                that.currentVideoId = player;
                that.currentItem = item;
                that.$set(item, "isPlay", true);
                that.playNum();
              }
            } else {
              that.currentVideoId = player;
              that.currentItem = item;
              item.isPlay = true;
              that.playNum();
            }
          });
          player.on("pause", function () {
            item.isPlay = false;
          });
          this.tyVideo[item.id] = player;
        });
      }
    },
    play1(item) {
      item.isLoad = true;
      item.isPlay = true;
      if (this.smallVideo[item.id] != null) {
        this.smallVideo[item.id].play();
      } else {
        var that = this;
        this.$nextTick(() => {
          var source = {};
          if (item.videoPaly.playInfoList) {
            item.videoPaly.playInfoList.forEach((item1) => {
              source[item1.definition] = item1.playURL;
            });
          }
          let player = new window.Aliplayer(
            {
              format: "m3u8",
              encryptType: "1",
              id: "svideo" + item.id,
              source: JSON.stringify(source),
              defaultDefinition: "SD",
              autoplay: true,
              width: "100%",
              height: "100%",
              cover: item.videoPaly.videoBase.coverURL,
              isLive: false,
              rePlay: false,
              preload: false,
            },
            function (player) {
              console.log(player);
            }
          );
          player.on("play", function () {
            if (that.currentVideoId != null) {
              if (that.currentItem.id == item.id) {
                if (item.showAlert) {
                  item.showAlert = false;
                  item.isPlay = true;
                  that.currentVideoId.seek(0);
                  that.currentVideoId.replay();
                  that.playNum();
                }
              } else {
                that.currentVideoId.pause();
                that.currentItem.isPlay = false;
                that.currentVideoId = player;
                that.currentItem = item;
                that.$set(item, "isPlay", true);
                that.playNum();
              }
            } else {
              that.currentVideoId = player;
              that.currentItem = item;
              item.isPlay = true;
              that.playNum();
            }
          });
          player.on("pause", function () {
            item.isPlay = false;
          });
          player.on("timeupdate", function () {
            if (player.getCurrentTime() >= 180 && !item.showAlert) {
              player.pause();
              try {
                that.exitfull();
              } catch {
                console.log("error");
              }
              item.showAlert = true;
              let alertNode = that.$refs.alert;
              alertNode.style = "";
            }
          });
          this.smallVideo[item.id] = player;
        });
      }
    },
    play2(item) {
      item.isLoad = true;
      item.isPlay = true;
      if (this.middleVideo[item.id] != null) {
        this.middleVideo[item.id].play();
      } else {
        var that = this;
        this.$nextTick(() => {
          var source = {};
          if (item.videoPaly.playInfoList) {
            item.videoPaly.playInfoList.forEach((item1) => {
              source[item1.definition] = item1.playURL;
            });
          }
          let player = new window.Aliplayer(
            {
              format: "m3u8",
              encryptType: "1",
              id: "mvideo" + item.id,
              source: JSON.stringify(source),
              defaultDefinition: "SD",
              autoplay: true,
              width: "100%",
              height: "100%",
              cover: item.videoPaly.videoBase.coverURL,
              isLive: false,
              rePlay: false,
              preload: false,
            },
            function (player) {
              console.log(player);
            }
          );
          player.on("play", function () {
            if (that.currentVideoId != null) {
              if (that.currentItem.id == item.id) {
                if (item.showAlert) {
                  item.showAlert = false;
                  item.isPlay = true;
                  that.currentVideoId.seek(0);
                  that.currentVideoId.replay();
                  that.playNum();
                }
              } else {
                that.currentVideoId.pause();
                that.currentItem.isPlay = false;
                that.currentVideoId = player;
                that.currentItem = item;
                that.$set(item, "isPlay", true);
                that.playNum();
              }
            } else {
              that.currentVideoId = player;
              that.currentItem = item;
              item.isPlay = true;
              that.playNum();
            }
          });
          player.on("pause", function () {
            item.isPlay = false;
          });
          player.on("timeupdate", function () {
            if (player.getCurrentTime() >= 180 && !item.showAlert) {
              player.pause();
              try {
                that.exitfull();
              } catch {
                console.log("error");
              }
              item.showAlert = true;
              let alertNode = that.$refs.alert;
              alertNode.style = "";
            }
          });
          this.middleVideo[item.id] = player;
        });
      }
    },
    play3(item) {
      item.isLoad = true;
      item.isPlay = true;
      if (this.largeVideo[item.id] != null) {
        this.largeVideo[item.id].play();
      } else {
        var that = this;
        this.$nextTick(() => {
          var source = {};
          if (item.videoPaly.playInfoList) {
            item.videoPaly.playInfoList.forEach((item1) => {
              source[item1.definition] = item1.playURL;
            });
          }
          let player = new window.Aliplayer(
            {
              format: "m3u8",
              encryptType: "1",
              id: "lvideo" + item.id,
              source: JSON.stringify(source),
              defaultDefinition: "SD",
              autoplay: true,
              width: "100%",
              height: "100%",
              cover: item.videoPaly.videoBase.coverURL,
              isLive: false,
              rePlay: false,
              preload: false,
            },
            function (player) {
              console.log(player);
            }
          );
          player.on("play", function () {
            if (that.currentVideoId != null) {
              if (that.currentItem.id == item.id) {
                if (item.showAlert) {
                  item.showAlert = false;
                  item.isPlay = true;
                  that.currentVideoId.seek(0);
                  that.currentVideoId.replay();
                  that.playNum();
                }
              } else {
                that.currentVideoId.pause();
                that.currentItem.isPlay = false;
                that.currentVideoId = player;
                that.currentItem = item;
                that.$set(item, "isPlay", true);
                that.playNum();
              }
            } else {
              that.currentVideoId = player;
              that.currentItem = item;
              item.isPlay = true;
              that.playNum();
            }
          });
          player.on("pause", function () {
            item.isPlay = false;
          });
          player.on("timeupdate", function () {
            if (player.getCurrentTime() >= 180 && !item.showAlert) {
              player.pause();
              try {
                that.exitfull();
              } catch {
                console.log("error");
              }
              item.showAlert = true;
              let alertNode = that.$refs.alert;
              alertNode.style = "";
            }
          });
          this.largeVideo[item.id] = player;
        });
      }
    },
    choiceClick(index) {
      console.log(index);
      if (index == 1) {
        this.$router.push({
          path: "/PESHome",
        });
      }
    },
    clickYd() {
      this.$hint({
        type: "load",
        message: "敬请期待",
      }).isShow();
    },
    exitfull() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    courseClick(index) {
      if (this.courseType != index) {
        this.courseType = index;
        if (index == 0) {
          document.getElementById("tyId").scrollIntoView();
        } else if (index == 1) {
          document.getElementById("smallId").scrollIntoView();
        } else if (index == 2) {
          document.getElementById("middleId").scrollIntoView();
        } else if (index == 3) {
          document.getElementById("largeId").scrollIntoView();
        }
      }
    },
    handleScroll() {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      var height = scrollTop + window.innerHeight;
      let anim1 = document.getElementById("anim1");
      let animY1 = anim1.offsetTop;
      if (height >= animY1 + 20) {
        this.anim1 = true;
      } else {
        this.anim1 = false;
      }
      let anim2 = document.getElementById("anim2");
      let animY2 = anim2.offsetTop;
      if (height >= animY2 + 20) {
        this.anim2 = true;
      } else {
        this.anim2 = false;
      }
      let smallId = document.getElementById("smallId");
      let smallY = smallId.offsetTop;
      let middleId = document.getElementById("middleId");
      let middleY = middleId.offsetTop;
      let largeId = document.getElementById("largeId");
      let largeY = largeId.offsetTop;
      if (scrollTop >= smallY - 400) {
        if (scrollTop >= middleY - 400) {
          if (scrollTop >= largeY - 400) {
            this.courseType = 3;
          } else {
            this.courseType = 2;
          }
        } else {
          this.courseType = 1;
        }
      } else {
        this.courseType = 0;
      }
    },
    goSk(item) {
      let data = {
        videoId: item.id,
      };
      this.api.pes.courseCountAdd(data).then((res) => {
        if (res.code == 20001) {
          // this.isRoute = true;
          // this.$store.commit("setLoginState", "login");
          // this.$router.push({
          //   path:'/admin/classroom/classConfig'
          // })
          this.$router.push({
            path:'/admin/classroom/onlineClass'
          })
        }
      });
    },
    confirm() {
      this.alertShow = false;
      let alertNode = this.$refs.alert;
      alertNode.style.display = "none";
    },
    Mouseover(index) {
      this.mounthType = index;
    },
    Mouseleave() {
      this.mounthType = -1;
    },
    setBillDetail() {
      this.isRoute = false;
    },
    getTyCourse() {
      let data = {
        courseId: 2,
        isPremium: 0,
      };
      this.api.pes.getLAGCourse(data).then((res) => {
        res.data.forEach((item) => {
          item.showAlert = false;
          item.isPlay = false;
          item.isLoad = false;
        });
        this.tyList = res.data;
      });
    },
    getSmallCourse() {
      let data = {
        courseId: 1,
        isPremium: 1,
      };
      this.api.pes.getLAGCourse(data).then((res) => {
        res.data.forEach((item) => {
          item.showAlert = false;
          item.isPlay = false;
          item.isLoad = false;
        });
        this.smallList = res.data;
      });
    },
    getMiddleCourse() {
      let data = {
        courseId: 37,
        isPremium: 1,
      };
      this.api.pes.getLAGCourse(data).then((res) => {
        res.data.forEach((item) => {
          item.showAlert = false;
          item.isPlay = false;
          item.isLoad = false;
        });
        this.middleList = res.data;
      });
    },
    getLargeCourse() {
      let data = {
        courseId: 38,
        isPremium: 1,
      };
      this.api.pes.getLAGCourse(data).then((res) => {
        res.data.forEach((item) => {
          item.showAlert = false;
          item.isPlay = false;
          item.isLoad = false;
        });
        this.largeList = res.data;
      });
    },
    // 点赞
    Praise(item) {
      let data = {
        videoId: item.id,
      };
      this.api.pes.coursePraise(data).then((res) => {
        this.$set(item, "isPraise", true);
        this.$set(item, "assisNum", item.assisNum + 1);
      });
    },
    // 取消点赞
    cancelPraise(item) {
      let data = {
        videoId: item.id,
      };
      this.api.pes.coursePraise(data).then((res) => {
        this.$set(item, "isPraise", false);
        this.$set(item, "assisNum", item.assisNum - 1);
      });
    },
    // 播放
    playNum() {
      let data = {
        videoId: this.currentItem.id,
      };
      this.api.pes.courseCountAdd(data).then((res) => {});
    },
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
  },
  mounted() {
    let _this = this;
    window.onscroll = function () {
      _this.handleScroll();
    };
    this.getTyCourse();
    this.getSmallCourse();
    this.getMiddleCourse();
    this.getLargeCourse();
  },

  beforeDestroy() {
    for (var key in this.tyVideo) {
      if (this.tyVideo[key]) {
        this.tyVideo[key].dispose();
      }
    }
    for (var key1 in this.smallVideo) {
      if (this.smallVideo[key1]) {
        this.smallVideo[key1].dispose();
      }
    }
    for (var key2 in this.middleVideo) {
      if (this.middleVideo[key2]) {
        this.middleVideo[key2].dispose();
      }
    }
    for (var key3 in this.largeVideo) {
      if (this.largeVideo[key3]) {
        this.largeVideo[key3].dispose();
      }
    }
    this.tyVideo = {};
    this.smallVideo = {};
    this.middleVideo = {};
    this.largeVideo = {};
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  overflow-x: hidden;
}
.tab_top {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  height: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #6a0203;
  padding-left: 184px;
  padding-right: 181px;

  .select {
    height: 30px;
    background: #aa0000;
    border-radius: 4px;
    text-align: center;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .normal {
    height: 30px;
    text-align: center;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .m68 {
    margin-right: 68px;
  }
  .m74 {
    margin-right: 74px;
  }
  .m46 {
    margin-right: 46px;
  }
  .m50 {
    margin-right: 50px;
  }
  .m87 {
    margin-right: 87px;
  }
  .m66 {
    margin-right: 66px;
  }
  .m54 {
    margin-right: 54px;
  }
  .m210 {
    margin-right: 210px;
  }
}
.flex {
  flex: 1;
}
.row_center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.m35 {
  margin-top: 35px;
}
.mk_text {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.m21 {
  margin-top: 21px;
}
.h211 {
  height: 211px;
}
.h55 {
  height: 55px;
}
.h200 {
  height: 200px;
}
.mt39 {
  margin-top: 39px;
}
.sticy {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course_normal {
  width: 411px;
  height: 78px;
  background: #cbcbcb;
  border: 8px solid #bfbfbf;
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  font-size: 46px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #848484;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yd_select {
  width: 411px;
  height: 78px;
  background: #00fcd0;
  border: 8px solid #44eddb;
  border-top-right-radius: 47px;
  border-bottom-right-radius: 47px;
  font-size: 46px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000101;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yd_normal {
  width: 411px;
  height: 78px;
  background: #cbcbcb;
  border: 8px solid #bfbfbf;
  border-top-right-radius: 47px;
  border-bottom-right-radius: 47px;
  font-size: 46px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #848484;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pos_rel {
  position: relative;
}
.logo_pos {
  position: absolute;
  top: 0px;
  left: 350px;
  width: 130px;
  height: 130px;
}
.width823 {
  width: 823px;
}
.width822 {
  width: 822px;
}
.tab_pos {
  position: absolute;
  left: 0;
  top: 17px;
}
.mt200 {
  margin-top: 200px;
}
.select1 {
  width: 52px;
  height: 124px;
  background: #00fcd0;
  border: 3px solid #44eddb;
  border-radius: 26px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000101;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  cursor: pointer;
}
.normal1 {
  width: 52px;
  height: 124px;
  background: #cbcbcb;
  border: 3px solid #bfbfbf;
  border-radius: 26px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #848484;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  cursor: pointer;
}
.mt4 {
  margin-top: 4px;
}
.ml22 {
  margin-left: 22px;
}
.column1 {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 300px;
}
.width52 {
  width: 58px;
}
.ddz_l {
  margin-right: 77px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 49px;
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .h200 {
    height: 200px;
    z-index: 1;
    width: 100%;
    background: #e7e7e7;
  }
}
.h1000 {
  height: 1000px;
}
.item_bg {
  display: flex;
  flex-direction: column;
  width: 822px;
  height: 668px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 17px;
  .image_l {
    width: 100%;
    height: 462px;
    position: relative;
    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .play {
      width: 95px;
      height: 95px;
      right: 33px;
      bottom: 21px;
      position: absolute;
      z-index: 1;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    margin-left: 34px;
    margin-top: 26px;
    margin-bottom: 35px;
    align-items: center;
    .label_text {
      width: 120px;
      height: 36px;
      background: #d2fcf8;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #44eddb;
    }
    .label_text1 {
      width: 120px;
      height: 36px;
      background: #fef2cc;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fac000;
    }
    .label_text2 {
      width: 120px;
      height: 36px;
      background: #f3c8b6;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff6c00;
    }
    .label_text3 {
      width: 120px;
      height: 36px;
      background: #b4cdef;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #003cff;
    }
    .text {
      width: 260px;
      height: 60px;
      background: #00fcd0;
      border: 8px solid #44eddb;
      border-radius: 58px;
      font-size: 34px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000101;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text1 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #bfbfbf;
      margin-left: 41px;
    }
    .column_l {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 34px;
      .image {
        width: 58px;
        height: 52px;
      }
      .num {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 9px;
      }
    }
  }
  .line {
    height: 2px;
    background: #e7e7e7;
    margin-left: 34px;
    margin-right: 34px;
  }
  .row1 {
    display: flex;
    flex-direction: row;
    padding-left: 34px;
    margin-top: 10px;
    align-items: center;
    .text {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #bfbfbf;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .column_l {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin-right: 32px;
      .time {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bfbfbf;
      }
      .unit {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bfbfbf;
      }
    }
  }
}
.h600 {
  height: 100px;
  width: 100%;
}
.ppp {
  display: flex;
  flex-direction: column;
  position: sticky;
  margin-top: 278px;
  top: 278px;
}
.course_select {
  width: 411px;
  height: 78px;
  background: #00fcd0;
  border: 8px solid #44eddb;
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  font-size: 46px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000101;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.column3 {
  display: flex;
  flex-direction: column;
  margin-left: 22px;
  .sticky {
    position: sticky;
    top: 0px;
    z-index: 2;
    padding-top: 80px;
    background: #e7e7e7;
    padding-bottom: 200px;
  }
}
.alert {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .alert_bg {
    display: flex;
    flex-direction: column;
    border-radius: 0.18rem;
    background: #fff;
    width: 560px;
    padding: 20px;
  }
  .closeImg {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .bb {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .imgg {
    width: 179px;
    height: 162px;
  }
  .message {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #bbbbbb;
    margin-bottom: 79px;
  }
}
/deep/ .prism-player {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
/deep/ .prism-player .prism-cover {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
/deep/ .prism-player .prism-big-play-btn {
  width: 0px;
  height: 0px;
  position: absolute;
  right: 30px;
  bottom: 60px;
}
/deep/ .prism-player .prism-big-play-btn .outter {
  width: 0px;
  height: 0px;
  border: 0px solid rgba(255, 255, 255, 0.51);
}
.sk {
  width: 120px;
  height: 36px;
  background: #d2fcf8;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #44eddb;
  margin-right: 60px;
  cursor: pointer;
}
</style>